<template>
  <router-view v-if="loadApp" />
</template>
<script>
import NotifyQueue from "src/utils/NotifyQueue.js";
import QuoteNotify from "src/dental-clinic-module/doctors/QuoteNotify.js";

export default {
  data: () => ({
    loadApp: false,
    notifyIntervalId: null,
  }),
  async created() {
    let result = this.$store.dispatch("getUser");

    result.catch(async () => {
      // error
      // console.warn("No se logro obtener usuario")
      await this.$store.dispatch("auth/logout");
      if(this.$route.name !== "login") {
        this.$router.push({ name: "login" });
      }
    });

    result.finally(() => {
      // console.info("Finally load");
      this.loadApp = true;
    });
    let notifyQueue = new NotifyQueue();
    //load quotenotification
    await notifyQueue.loadNotifications(QuoteNotify.getTodayQuotes());
    notifyQueue.processAll()
  },

  beforeDestroy() {
    if(this.notifyIntervalId) {
      clearInterval(this.notifyIntervalId);
    }
  }
}
</script>
<style lang="scss">
@import "./style.scss";
</style>
