/**
 * Author: Wilson Pilco Nuñez
 * Email: wilsonaux1@gmail.com
 * Created at: 2025-03-04 20:24
 */
import _ from "lodash";
import {secureStorage} from "src/auth-module/secureStorage.js";
import {DentalClinicService} from "../service";
import {myMoment} from "src/utils/myMoment.js";

const LS_KEY = "APP_DENTAL_CLIENT_AGENDA_NOTIFY_DOCTORS";

class QuoteNotify {
  static getEmployeeNotifyList() {
    let prev = [];
    try {
      prev = JSON.parse(secureStorage.getItem(LS_KEY)) || [];
    } catch (err) {
      prev = [];
    }
    return prev;
  }

  static addEmployeeId(id) {
    let prev = QuoteNotify.getEmployeeNotifyList();
    prev = _.sortedUniq([...prev, id]);
    secureStorage.setItem(LS_KEY, JSON.stringify(prev));
  }

  static removeEmployee(id) {
    let prev = QuoteNotify.getEmployeeNotifyList();
    prev.splice(prev.indexOf(id), 1);
    secureStorage.setItem(LS_KEY, JSON.stringify(prev));
  }

  static async getTodayQuotes() {
    let list = QuoteNotify.getEmployeeNotifyList();
    if (list.length > 0) {
      const today = myMoment();
      let res = await DentalClinicService.getQuotes({
        date: today.format("YYYY-MM-DD"),
        per_page: 1000,
      });
      let now = myMoment();
      return res.data
        .filter(x => list.includes(x.employee_id))
        .filter(x => {
          let time = myMoment(x.ini_date);
          return now.valueOf() < time.valueOf();
        })
        .map(x => {
          let time = myMoment(x.ini_date);
          //let toTimestamp = myMoment();
          //toTimestamp.add(15, 'second');
          return {
            tag: `APP_DC_QUOTE_${x.id}`,
            date: time.format("YYYY-MM-DD"),
            title: `A las ${time.format('hh:mma')}`,
            content: `Paciente ${x.client_name}, se realizara ${x.commentary}`,
            //timestamp: toTimestamp.valueOf(),
            timestamp: time.valueOf() - (10 * 60 * 1000),
          };
        });
    } else {
      return [];
    }
  }
}

export default QuoteNotify;
