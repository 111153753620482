import {secureStorage} from "src/auth-module/secureStorage.js";

class NotifyQueue {
  constructor() {
    this.mainQueue = [];
    this.currentQueue = [];
  }

  addNotification(notification) {
    this.mainQueue.push(notification);
  }

  async loadNotifications(list) {
    let notifications = await list
    notifications.forEach(notification => {
      this.addNotification(notification);
    });
  }

  processAll() {
    const now = new Date();

    this.mainQueue.forEach(notification => {
      const notificationTime = new Date(notification.timestamp);
      const delay = notificationTime - now;
      if (delay > 0) {
        setTimeout(() => {
          window.Notification.requestPermission().then(permission => {
            new Notification(notification.title, {
              body: notification.content,
              timestamp: notification.timestamp,
              tag: notification.tag,
              renotify: false,
            });
          });
        }, delay);
      } else {
        // If the time has already passed, execute immediately
        window.Notification.requestPermission().then(permission => {
          new Notification(notification.title, {
            body: notification.content,
            timestamp: notification.timestamp,
            tag: notification.tag,
            renotify: false,
          });
        });
      }
    });
  }

  process() {
    const now = new Date();

    // Move notifications from mainQueue to currentQueue if their time has come
    this.mainQueue = this.mainQueue.filter(notification => {
      if (new Date(notification.time) <= now) {
        this.currentQueue.push(notification);
        return false;
      }
      return true;
    });

    // Process currentQueue
    this.currentQueue.forEach(notification => {
      // Execute the notification (assuming it has an execute method)
      window.Notification.requestPermission().then(permission => {
        new Notification(notification.title, {
          body: notification.content,
        });
      });
    });

    // Clear currentQueue after processing
    this.currentQueue = [];
  }
}

export default NotifyQueue;
